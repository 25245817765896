<template>

  <div>

    <user-list-add-new v-if="lectureList" :key="lectureList.length" :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
      :plan-options="planOptions" @refetch-data="refetchData" :lectureIndex = "lectureList.length"/>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />

              <b-button variant="primary" @click="newLecture">

                <span class="text-nowrap">{{$t('Create Lecture')}}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <div class="mx-2 mb-2">

      </div>
      <div>
        <draggable v-model="lectureList" class="list-group list-group-flush cursor-move" tag="ul">
          <transition-group type="transition" name="flip-list">
            <b-list-group-item v-for="listItem in lectureList" :key="listItem.id" tag="li">
              <div class="d-flex">
                <div md="1" class="ml-25 col-1">
                  <b-avatar :text="(listItem.lectureorder + 1).toString()" />
                </div>
                <div md="5" class="ml-25 col-6">
                  {{ listItem.title }}
                </div>
                <div md="3" class="ml-25 col-3">
                  <b-link
                    :to="{ name: 'course-lecturelessons', params: { id: listItem.courseguid, lectureid: listItem.id, lecturetitle: listItem.title } }"
                    class="font-weight-bold d-block text-nowrap">
                    <feather-icon icon="BookIcon" />
                    {{ listItem.lessons.length }} {{ $t("Lessons") }}
                  </b-link>

                </div>
                
                <div md="3" class="ml-25 col-3">
                  <b-button variant="outline-primary" @click="changeCategory(listItem)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </transition-group>
        </draggable>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BListGroupItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import draggable from 'vuedraggable'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './LecturesListFilters.vue'
import useUsersList from './useLecturesList'
import userStoreModule from '../lectureStoreModule'
import UserListAddNew from './LectureListAddNew.vue'

export default {

  computed: {

  },
  provide() {
    const categorydata = {}

    Object.defineProperty(categorydata, "selectedCategory", {
      enumerable: true,
      get: () => this.selectedCategory,
    })

    return {

      categorydata,
    }
  },
  data() {
    return {
      migrationStarted: false,
      selectedCategory: null,// this.generalData.generalsettings,

    }
  },
  methods: {
    getRoute(coursemode) {

      return "page-coursemode";
    },
    changeCategory(e) {
      this.selectedCategory = e;
      this.isAddNewUserSidebarActive = true;
      //console.log(e);
    },

    deleteLecture(e) {
      this.selectedCategory = e;
      this.isAddNewUserSidebarActive = true;
      //console.log(e);
    },
    newLecture() {
      this.selectedCategory = {
        id: null,
        title: null,
        description: null
      };
      this.isAddNewUserSidebarActive = true;

    },
  },

  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    draggable, BListGroupItem,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'course-lectures'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const sessionsData = ref(null)

    var activeId = null;
    if (router.currentRoute.params.id) {
      //console.log("using from route");
      activeId = router.currentRoute.params.id;
    }
    else {
      //console.log("using from getter");
      var unit = store.getters['app/currentUnit'];
      activeId = unit;

    }

    store.commit('app/SETACTIVEUNIT', activeId)

    //console.log("incoming id: "+ activeId);


    //     store.dispatch('course-session/fetchSessions',  router.currentRoute.params.id )
    //     .then(response => {
    //  //    var course=JSON.parse(JSON.stringify(response.data));
    //     //  const { course  } = response.data
    //     //console.log(response);
    //       sessionsData.value =  response.data;
    //       //console.log("courses assigned to course data");
    //       // //console.log(response.data);


    //       })
    //     .catch(error => {
    //       if (error.response.status === 404) {
    //         sessionsData.value = undefined
    //       }
    //     })

    const courseguidval = ref(activeId);
    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectures,

    } = useUsersList(courseguidval)


    const lectureList = computed({
      get() {
        return lectures.value;

      },
      set(oldValue) {
        // set something
        //console.log("updated");
        lectures.value = oldValue;
        var newORders = [];

        var index = 0;

        lectures.value.forEach(element => {
          newORders.push({ id: element.id, order: index });
          index++;

        });
        //console.log(newORders);
        reOrderLessons(newORders);




      },
    });

    const reOrderLessons = (data) => {

      var postData = {};
      postData.act = "REORDER";
      postData.orders = data;


      // postData.lectureid=router.currentRoute.params.lectureid;
      postData.courseguid = router.currentRoute.params.id;


      store
        .dispatch('course-lectures/reOrderLessons',
          postData,

        )
        .then(response => {

          refetchData();

        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching courses list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    }
    refetchData();

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchCourses,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusVariantText,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      resolvePageroute,
      resolveCourseContent,
      courseguid,
      lectureList,
      reOrderLessons
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
